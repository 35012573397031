"use client"

import { User } from "@/types/User"
import { useEffect } from "react"
import { useAppDispatch } from "@/store/store"
import { authActions } from "@/store/auth/authSlice"
import { ArtistOrBrand } from "@/types/ArtistOrBrand"
import { commonActions } from "@/store/common/commonSlice"

const HydrateReduxInitialState = ({
  user,
  masquerade,
  calendarEvents,
}: {
  user?: User
  masquerade: ArtistOrBrand
  calendarEvents?: any[]
}) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }
    if (calendarEvents?.length) {
      dispatch(commonActions.setCalendarEvents(calendarEvents))
    }
    dispatch(authActions.setUser(user))

    const hydratedMasquerade = [
      ...(user?.artist_profiles || []),
      ...(user?.brand_profiles || []),
    ].find(({ slug }) => slug === masquerade?.slug)

    dispatch(
      authActions.setMasquerade(
        hydratedMasquerade
          ? { ...hydratedMasquerade, type: masquerade?.type }
          : masquerade
      )
    )
  }, [calendarEvents, dispatch, masquerade, user])

  return null
}

export default HydrateReduxInitialState
